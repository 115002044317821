import React, { useEffect, useState } from 'react';
import { DEVICE_TYPE } from 'src/models/device.model';
import { getDeviceBreakpoints, getDeviceType } from './dom.utls';

export const useDevice = () => {
  const [updateTrigger, setUpdateTrigger] = useState(Date.now());
  const [deviceType, setDeviceType] = React.useState(getDeviceType());

  const handleResize = React.useCallback((e) => {
    // navigator

    setDeviceType(getDeviceType());
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(null);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isMobile, isTablet, isDesktop, isWideDesktop } = React.useMemo(
    () => ({
      isMobile: deviceType === DEVICE_TYPE.MOBILE,
      isTablet: deviceType === DEVICE_TYPE.TABLET,
      isDesktop: deviceType === DEVICE_TYPE.DESKTOP,
      isWideDesktop: deviceType === DEVICE_TYPE.WIDE_DESKTOP,
    }),
    [deviceType]
  );

  useEffect(() => {
    setUpdateTrigger(Date.now());
  }, [isMobile, isTablet, isDesktop, isWideDesktop]);

  return { isMobile, isTablet, isDesktop, isWideDesktop, type: deviceType };
};
