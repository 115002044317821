import { DEVICE_BREAKPOINTS, DEVICE_TYPE } from 'src/models/device.model';

export const getDeviceBreakpoints = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isLandscape = window.orientation > 0;

  const targetSize = isLandscape ? height : width;
  const breakpoints =  Object.entries(DEVICE_BREAKPOINTS).sort((a, b) => {
    return a[1][0] > b[1][0] ? -1 : 1;
  }).find(([type, [from, to]]) => {
    const isFrom = from == null || targetSize >= from;
    const isTo = to == null || targetSize <= to;

    return isFrom && isTo;
  }) || [DEVICE_TYPE.DESKTOP];

  return breakpoints;
};

export const getDeviceType = (): DEVICE_TYPE => {
  const breakpoints = getDeviceBreakpoints();

  return Number(breakpoints[0]);
};

export const findParentWithScroll = (target = null) => {
  if (target == null) {
    return null;
  }

  const parent = target.parentNode;

  if (parent == null || parent === document) {
    return document.scrollingElement;
  }

  let styles = null;

  try {
    styles = getComputedStyle(parent);
  } catch (err) {
    return null;
  }
  
  const overflow = styles?.overflow;

  const isHidden = overflow === 'hidden';
  const parentHeight = parent.offsetHeight;
  const scrollHeight = parent.scrollHeight;

  if (!isHidden && parentHeight < scrollHeight) {
    return parent;
  }

  return findParentWithScroll(parent);
};
