export const FILE_STORAGE_DOMAIN = 'https://storage.cloud.google.com/kate-shmat/';

export enum APP_SECTION_TYPE {
  CONTAINER = 0,
  INTRO = 1,
  ABOUT,
  DETAILS,
  COUNTER,
  COMMENTS,
  SERVICES,
  GALLERY,
  TOGGLE_TEXT,
  FOOTER,
  TEACHING,
  EQUIPMENT,
  QUESTIONS,
  CONTACTS,
  CONSULTING
}

export enum APP_CONTENT_TYPE {
  EMPTY = -1,
  COMMENTS,
}
