import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { initTranslations } from 'src/common/language/language.utils';
import { useDevice } from 'src/common/dom/useDevice';
import { FILE_STORAGE_DOMAIN } from 'src/app/app.content.constants';
// import AppRouter from 'src/app/AppRouter';
import '/src/app/app.css';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';

const MIXPANEL_TOKEN = '45633fa18d2ecbe0f7f4d599b27d4598';
const USER_ID = 'dmitry.shamak.dev@gmail.com';

mixpanel.init(MIXPANEL_TOKEN);
// mixpanel.identify(USER_ID);

mixpanel.track('APP START', {
  date: new Date().toISOString()
});

const AppRouter = lazy(() => import('src/app/AppRouter'));

initTranslations();

const App = () => {
  const [isReady, setReady] = useState(false);
  const { isDesktop, isTablet, isMobile } = useDevice();

  useEffect(() => {
    mixpanel.track("App Init");

    try {
      document.addEventListener('DOMContentLoaded', () => setReady(true));

      if (document.readyState === 'interactive' || document.readyState === 'complete') {
        setReady(true);
      }
    } catch (err) {
      setReady(true);
    }
  }, []);

  return (
    <MixpanelProvider mixpanel={mixpanel}>
      <main
        id="application"
        className={classNames({ invisible: !isReady, desktop: isDesktop, tablet: isTablet, mobile: isMobile })}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shmat</title>
          <link rel="preconnect" href="https://fonts.googleapis.com"></link>
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin=""></link>
          <link rel="preconnect" href={FILE_STORAGE_DOMAIN}></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600&display=swap"
            rel="stylesheet"
          ></link>
        </Helmet>
        <Suspense fallback={<div>loading..</div>}>
          <AppRouter />
        </Suspense>
      </main>
    </MixpanelProvider>
  );
};

export default withTranslation()(App);
